/**
 * Created by zhangsanfeng on 2020/07/02.
 */

import { Message } from 'element-ui'

export const tool = {

  mathRandom: function (min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  },

  // success warning error
  message(text, type, callback) {
    Message({
      message: text,
      type: type,
      duration: 2000,
      onClose: () => {
        callback && callback()
      }
    })
  },

  /* 数组去重
    param:{
        arr: 入参数组,
        key: 主键
    }
  */
  unique(arr) {
    var result = []
    var obj = {}
    for (var i = 0; i < arr.length; i++) {
      if (!obj[arr[i].name]) {
        result.push(arr[i])
        obj[arr[i].name] = true
      }
    }
    return result
  },

  getNowDate() {
    var date = new Date();//时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var Y = date.getFullYear() + '-';
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());

    return Y + M + D;

  },
  //地图加载
  BMapLoader(ak) {
    return new Promise((resolve, reject) => {
      if (window.BMapGL) {
        resolve(window.BMapGL)
      } else {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = 'https://api.map.baidu.com/api?v=1.0&type=webgl&callback=initBMap&ak=' + ak
        script.async = true
        script.onerror = reject
        document.head.appendChild(script)
      }
      window.initBMap = function () {
        resolve(window.BMapGL)
      }
    })
  }
}

<!--
设备管理
-->
<template>
  <div class="mainBox">
    <!---->
    <div class="shipMain" id="allmap"></div>

    <el-dialog title="" :visible.sync="showCamera" width="1000px">
      <div class="cameraBox" id="video-container"></div>
    </el-dialog>
  </div>
</template>
<script>
import { tool } from "../../common/tool";
import { GeSecurityDevice, GetVedioToken } from "../../api/homeGuardianship";
import EZUIKit from "ezuikit-js";

export default {
  components: {},
  data() {
    return {
      timeData: "",
      changeIndex: 1,
      showCamera: false,
      map: "",
      camera: require("../../assets/img/Camera.png"),
    };
  },
  created() {},

  mounted: function () {
    this.initMap();
  },
  destroyed: function () {},
  computed: {},
  watch: {},
  methods: {
    initMap() {
      this.$nextTick(() => {
        tool.BMapLoader("nYfEeFGdFAByruCSLWrnmRl8Ed7leV0y").then((BMap) => {
          const map = new BMap.Map("allmap", { enableMapClick: false });
          this.map = map;
          map.disableKeyboard(); // 禁用键盘操作地图
          map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放
          // 添加中心点和缩放等级，如果不添加，初始化出来页面为白屏
          const point = new BMap.Point(114.305392, 30.593098);
          map.centerAndZoom(point, 13);
          //获取打点信息
          this.geSecurityDevice(BMap, map);
        });
      });
    },
    confirmAlarmRecord(id) {
      console.log(id);
      this.showCamera = true;
      GetVedioToken(id).then((res) => {
        console.log(res);
        this.palyVideo(res.result.token, res.result.playUrl);
      });
    },
    palyVideo(accessToken, url) {
      new EZUIKit.EZUIKitPlayer({
        id: "video-container", // 视频容器ID
        accessToken: accessToken,
        url: url,
      });
    },

    geSecurityDevice(BMap, map) {
      let self = this;

      GeSecurityDevice().then((res) => {
        console.log(res);
        let markerArr = [];
        let info = []; //弹窗信息数组

        let mapData = res.result || [];
        if (mapData.length > 0) {
          for (let index = 0; index < mapData.length; index++) {
            let mapItem = mapData[index];

            let sContent = "";
            let point = new BMap.Point(mapItem.longitudeX, mapItem.latitudeY);
            var myIcon = new BMap.Icon(self.camera, new BMap.Size(72, 72));
            var marker = new BMap.Marker(point, {
              icon: myIcon,
            });
            markerArr.push(marker);
            var opts = {
              width: 322, // 信息窗口宽度
              height: 234, // 信息窗口高度
            };
            sContent = `
            <div>
    <img style='' id='deviceImg' src=${mapItem.imgUrl} width='300' height='166' title="${mapItem.id}"/>
          <p style='margin:0;;font-size:13px;text-indent:2em;
color: #1054EF;'>
        ${mapItem.name}
          </p>
            <p style='margin:0;font-size:13px;text-indent:2em;color: #1054EF;'>
        ${mapItem.address}
          </p>
    </div>`;

            info[index] = new BMap.InfoWindow(sContent, opts); // 创建信息窗口对象

            markerArr[index].addEventListener("click", function () {
              map.openInfoWindow(info[index], point);
              setTimeout(() => {
                document.getElementById("deviceImg").onclick = function () {
                  self.confirmAlarmRecord(
                    document.getElementById("deviceImg").title
                  );
                };
              }, 100);
            });

            map.addOverlay(marker);
          }
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.cameraBox {
  width: 100%;
  height: 500px;
}
</style>
